import Vue from "vue";

/*TITLE*/
document.title = "BENIGNO GRANIZO 27 | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "BENIGNO GRANIZO 27";
Vue.prototype.$subtitle = "POZUELO DE ALARCÓN | MADRID";

/*INTRO*/
Vue.prototype.$promoter = "BENIGNO GRANIZO 27";
Vue.prototype.$introSubtitle = "POZUELO DE ALARCÓN | MADRID";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "0-livingroom--20221124091642.jpeg";
Vue.prototype.$image_kitchen = "0-kitchen--20221124091642.jpeg";
Vue.prototype.$image_bath1 = "0-bathroom1--20221124091642.jpeg";
Vue.prototype.$image_bath2 = "0-bathroom2--20221124091642.jpeg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "0-livingroom--20221124091642.jpeg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "0-kitchen--20221124091642.jpeg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "0-bathroom1--20221124091642.jpeg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "0-bathroom2--20221124091642.jpeg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/benignogranizo/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = false;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20benigno%20granizo%2027%20pozuelo%20de%20alarcón",
    text: "Calle Benigno Granizo 27, Pozuelo de Alarcón",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20doctor%20ulecia%206%20bajo%20b;%20cp:28224%20pozuelo%20de%20alarcón%20madrid",
    text: "Calle Doctor Ulecia 6,Bajo B; CP:28224 Pozuelo de Alarcón, Madrid",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:914666089",
    text: "914666089",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@monterenta.com",
    text: "info@monterenta.com",
  },
];
